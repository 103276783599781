* {
  padding: 0px;
  margin: 0px;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;

  font-family: 'Alexandria', sans-serif;


}


:root {
  --main-color: #27b5dd;
  --main-color-hover: #2ebee6;

  --background-color: #eff3f5;
  --card-color: #ffffff;
  --wight-color: #ffffff;
  --color-text: #001433;
  --gray-color: #455267;

}

button {
  background: transparent;
  font-size: 16px;
}


a, button {
  color: var(--color-text) !important;
}

p {
  line-height: 25px;
  color: var(--gray-color) !important;

}

section {
  position: relative;
  z-index: 0;
}

.react-photo-gallery--gallery {
  object-fit: cover !important;

}
img {
  height: auto;
}

button {
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
}

::-webkit-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-moz-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

:-ms-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-ms-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-moz-selection {
  background-color: var(--main-color);
  color: whitesmoke !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
  background-image: initial !important;
}

::selection {
  background-color: var(--main-color);
  color: whitesmoke !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
  background-image: initial !important;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}


/* <!-- ============================  alert ============================ --> */
.alert-div-parent {
  padding: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: var(--Dark2-color);
  width: 90%;
  max-width: 590px;
  text-transform: capitalize;
  transition: 0.3s;
  position: fixed;
  top: -100%;
  z-index: 55555555555555555;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.alert-div-parent.active {
  z-index: 55555555555555555;
  top: 90px;
}

.icon-alert {
  display: flex;
}

.icon-alert img {
  width: 30px;
}

.alert-div-parent p {
  color: var(--Dark-color);
  text-align: initial;
}

.alert-error {
  background: #fdedef;
  border: 1px solid #f04c62;
}

.alert-error img {
  filter: invert(53%) sepia(90%) saturate(4493%) hue-rotate(326deg) brightness(99%) contrast(90%);
  -webkit-filter: invert(53%) sepia(90%) saturate(4493%) hue-rotate(326deg) brightness(99%) contrast(90%);
}

.alert-warning {
  background: #fff9e9;
}

.alert-warning img {
  filter: invert(91%) sepia(11%) saturate(5734%) hue-rotate(333deg) brightness(103%) contrast(99%);
}

.alert-info {
  background: #e6fafd;
}

.alert-info img {
  filter: invert(63%) sepia(59%) saturate(2982%) hue-rotate(143deg) brightness(102%) contrast(101%);
}

.alert-success {
  background: #eafbf6;
  border: #37d19c 1px solid;
}

.alert-success img {
  filter: invert(83%) sepia(11%) saturate(2588%) hue-rotate(98deg) brightness(89%) contrast(82%);
}

/* <!-- ======================== Card Grid ======================== --> */

.grid-style {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  position: relative;
  gap: 20px;
}

.grid-style-tow {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  position: relative;
  gap: 50px;
  align-items: center;
}

.grid-style-one {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
}




@media (max-width: 1000px) {

  .grid-style-tow {
    display: flex;
    flex-direction: column-reverse;
  }
}



@media (max-width: 1024px) {

  .grid-style-tow {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

}

/* <!-- ======================== TittleSection ======================== --> */

.tittle-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px 0;
}

.tittle-section p {
  width: 100%;
  max-width: 700px;
}

.p-section-name {
  width: fit-content;
  padding: 10px 25px;
  background: var(--main-color);
  color: var(--wight-color) !important;
  border-radius: 65px;
  width: fit-content !important;
}

/* <!-- ======================== Flex ======================== --> */
.flex-div-column-10, .flex-div-column-20 {
  display: flex;
  flex-direction: column;
}

.flex-div-column-10 {
  gap: 10px;
}

.flex-div-column-20 {
  gap: 20px;
}

.flex-div-5, .flex-div-10, .flex-div-20 {
  display: flex;
  align-items: center;
}

.flex-div-5 {
  gap: 5px;
}




/* <!-- ======================== grid-achievement ======================== --> */

.flex-achievement {
  display: flex;
  position: relative;
  width: 100%;
  gap: 40px;
  flex-wrap: wrap;
}

@media (max-width:700px) {
  .flex-achievement {
    gap: 20px;

  }

  .grid-achievement, .flex-achievement h3 {

    font-size: 25px !important;
  }

  .h1-text-div p {

    font-size: 16px !important;

  }

  .h1-text-div h2 {
    color: var(--wight-color);
    font-size: 30px !important;
  }
}

.grid-achievement {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  position: relative;
  gap: 20px;
  width: 100%;
  gap: 40px;
}

.grid-achievement, .flex-achievement h3 {
  color: var(--main-color);
  font-size: 40px;
  font-weight: 700;
}

.grid-achievement, .flex-achievement p {
  color: var(--color-text);
  font-size: 18px;
  font-weight: 400;

}

/* <!-- ======================== Button ======================== --> */

.button {
  border-radius: 10px;
  background: var(--main-color) !important;
  padding: 15px 20px;
  color: var(--wight-color) !important;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
}

.button:hover {
  background: var(--main-color-hover);

}


/* <!-- ======================== Body ======================== --> */

body {
  background-color: var(--background-color);
  color: var(--color-text);
  overflow-x: clip;
}

.App {
  overflow-x: clip;
}

.container {
  width: 90%;
  margin: 0px auto;
  max-width: 1375px;
}




/* ======================== Header ======================== */
header {
  padding: 20px;
  position: fixed;
  width: 90%;
  top: 10px;
  z-index: 100;
  background: #ffffff;
  max-width: 1310px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  backdrop-filter: blur(8px);
}

header a {
  color: var(--wight-color);
}

/* header::after {
  content: "";
  width: 80%;
  position: absolute;
  bottom: 0;

  background: linear-gradient(90deg,
      rgba(2, 0, 36, 0) 0%,
      var(--main-color) 49%,
      rgba(0, 212, 255, 0) 100%);
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
} */

header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.button-div-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  min-width: 196px;
  justify-content: end;
}


.button-div-header .login-div {
  cursor: pointer;
}

.default-button {
  background: var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-flex-negative: 0;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-weight: 500;
  padding: 15px;
  border-radius: 10px;
  min-width: 190px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  text-align: center;
  color: var(--wight-color);
  flex-shrink: 0;
  padding: 20px;
  cursor: pointer;
  display: block;

  text-transform: capitalize;
  font-size: 16px;
}



.default-button:hover {
  background-color: var(--main-color-hover);
}

.a-logo img {
  height: 50px;

}

.a-logo {
  width: fit-content;
}

.sing-up-div {
  position: relative;
}

.down-sing-up {
  color: var(--Dark-color);
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  text-align: center;
  right: 0 !important;
  top: 70px;
  padding: 15px !important;
  width: 220px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  border: initial !important;
  animation: jumpInfinite 2s infinite;
  -webkit-animation: jumpInfinite 2s infinite;
  display: none;
}

.down-sing-up::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--main-color);
  top: -15px;
  right: 28px;
}

.down-sing-up.active {
  display: block !important;
}

.list-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-website a {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  font-weight: 500;

}

.list-website li:hover a {
  color: var(--main-color);
}

.toggle {
  cursor: pointer;
  display: none;
}





/* phone Header */

.list-website.active {
  right: 0;
}

@media (max-width: 900px) {
  .list-website {
    display: flex;
    gap: 20px;
    position: fixed;
    flex-direction: column;
    height: 100vh;
    background: #fff;
    bottom: 0;
    top: 104px;
    right: 0;
    width: 70%;
    padding: 20px;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    z-index: 5555;
    right: -100%;
    transition: 0.3s;
    align-items: start;
    height: fit-content;
    border-radius: 20px;
  }

  .toggle {
    display: block !important;
  }
}


/* ======================== title-sec ======================== */
.title-sec {
  /* margin: 100px 0; */
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 70px;
}

.title-sec h2 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--dark-main-color);
  font-weight: 300;
  position: relative;
  width: fit-content;
  border: 1px solid var(--main-color);
  flex-shrink: 0;
  text-transform: capitalize;
}

.title-sec h3 {
  color: var(--color-text);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;

}

.gradient-span {
  color: var(--main-color);

}

/* 
.title-sec h2::before {
  content: "";
  position: absolute;
  height: 4px;
  width: 60vw;
  background: linear-gradient(270deg, rgba(240, 82, 85, 0.00) 0%, #F04E4D 52.08%, #F05255 100%);
  left: 204px;
  z-index: -1;
} */

.div-text-line {
  display: flex;
  align-items: center;
}

.div-text-line .line-span {
  content: "";
  height: 4px;
  width: 100%;
  background: linear-gradient(270deg, rgba(240, 82, 85, 0.00) 0%, #F04E4D 52.08%, #F05255 100%);
  left: 204px;
  z-index: -1;
}


.title-sec p {
  width: 90%;
  max-width: 1040px;
  color: var(--gray-color);
}

.light-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--main-color);
  box-shadow: 0px 0px 25px var(--main-color);
}

/* ======================== title-sec-new ======================== */



.new-title-sec {
  text-align: center;
}

.new-title-sec h1 {
  font-size: 40px;
  text-transform: capitalize;
}

.new-title-sec p {
  margin: auto;
}



/* <!-- ============================ card-date ============================ --> */

.card-date-grid {
  position: relative;
  z-index: 1;
  margin-top: 55px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  gap: 20px;
  margin: 50px auto;
}

.card-date-grid-div {
  margin-top: 60px;
  gap: 20px;
}

.link-tour {
      color: var(--main-color) !important;
    font-size: 20px;
}
.card-div-message {

  padding: 20px;
  border-radius: 5px;
  background: var(--card-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.email-phone-div {
  display: flex;
  gap: 10px;
  display: flex;
}

.email-phone-div a {
  border-radius: 10px;
  padding: 15px 20px;
  color: var(--wight-color) !important;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background: var(--main-color);
}

.email-phone-div img {
  width: 24px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(254deg) brightness(106%) contrast(99%);
}

.card-date {
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  border-radius: 12px;
  background: var(--card-color);
  position: relative;
  z-index: 1;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  position: relative;
}

.card-date::after {
  content: "";
  width: 70%;
  height: 10px;
  background-color: var(--main-color);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
}

.card-date .time-date h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.buttons-date {
  display: flex;
  width: 100%;
  gap: 10px;
}

.buttons-date button {
  width: 100%;
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
}

.buttons-date .delete-button {
  background: #ff6d6d;
  color: var(--wight-color);
}

.buttons-date .available-button {
  background: #6dffdc;
}

.add-new-date {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  min-height: 200px;
}

.form-subscribe {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.form-subscribe textarea {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--main-color);
  max-height: 140px;
}


.data-client {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.data-client img {
  width: 24px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg) brightness(107%) contrast(105%);
}

.div-icon-data {
  display: flex;
  align-items: center;
  gap: 5px;
}

.p-card {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.p-card.active {
  display: -webkit-box;
  -webkit-line-clamp: inherit !important;
  -webkit-box-orient: inherit !important;
  overflow: inherit !important;
}

.p-card-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.show-more {
  text-transform: capitalize;
  color: var(--main-color);
  cursor: pointer;
}

/* ======================== main ======================== */

.main-sec {
  margin-top: 0;
  width: 100%;
  position: relative;

  padding-bottom: 80px;
  z-index: 55;

}

.first-section {

  padding-top: 130px;

}

.background-img-main {
  position: absolute;
  left: 0;
  top: -23px;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.background-img-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomTransform 30s linear infinite;
  -webkit-animation: zoomTransform 30s linear infinite;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

@keyframes zoomTransform {

  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

}

.main-sec lord-icon {
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.main-contact {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
}




.main-sec .container {
  position: relative;
  z-index: 5;
}

.h1-text {
  text-align: start;
  z-index: 1;
  font-size: 67px;
}




.result-p {
  text-align: center;
  margin-top: 14px;
  color: var(--gray-color);
  font-weight: 200;
  position: relative;
  padding-bottom: 20px;
}

.h1-text-div {
  text-align: start;
  /* margin-bottom: 40px;
  margin-top: 56px; */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.h1-text-div p {
  color: var(--wight-color) !important;
  max-width: 790px;
  font-size: 17px;
  line-height: 30px;
}

.h1-text-div h2 {
  color: var(--wight-color);
  font-size: 60px;
}




@keyframes moveUp {
  0% {
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
  }

  100% {
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
  }
}

.title-page .h1-text-div {
  margin-top: 110px;
  font-size: 40px;
}


/* <!-- ======================== search-div ======================== --> */

.search-div {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: var(--card-color);
  border-radius: 15px;
  justify-content: space-between;
  position: relative;
  z-index: 30;
}

.select-div-parent {
  display: flex;
  gap: 20px;
  border-radius: 15px;
  width: 100%;
}

.icon-select {
  display: flex;
  gap: 5px;
}

.icon-select p {
  color: var(--color-text) !important;
}

.search-button {
  width: 90px;
  background: var(--main-color);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.search-button img {
  width: 70%;
  max-width: 30px;
}




/* ======================== control ======================== */
.div-select {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  position: relative;
  z-index: 5;
}

.css-13cymwt-control {
  padding: 10px;
  width: 100%;
}

/* <!-- ======================== Project Section ======================== --> */

.card-projects {
  position: relative;
}

.card-projects img {
  height: 100%;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  object-fit: cover;
}

.price {
  position: absolute;
  z-index: 5;
  left: 30px;
  top: 30px;
  background: var(--main-color);
  color: var(--wight-color);
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.discount {
  position: relative;
  color: var(--wight-color) !important;
  opacity: 0.5;
  font-weight: 200;
  font-size: 20px;
}

.price-p {
  color: var(--wight-color) !important;
  font-size: 20px;
}

.discount::after {
  content: "";
  width: 80%;
  height: 1.5px;
  background: var(--wight-color);
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%) rotate(-35deg);
}

.div-details-text-name .discount::after {
  color: var(--gray-color) !important;
  background: var(--gray-color);

}

.discount-page {
  color: var(--gray-color) !important;
  font-weight: 400;
}

.div-discount {
  font-size: 18px;
}

.card {
  background: var(--card-color);
  padding: 20px;
  border-radius: 25px;
  height: 100% !important;
  position: relative;
}

.details-card {
  display: flex;
  border-top: 1px solid var(--gray-w, #E5E6EA);
  padding-top: 10px;
  margin-top: 10px;
  gap: 20px;
}

.icon-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.div-card-tittle-place {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.div-card-tittle-place h1 {
  font: 400 25px Alexandria, -apple-system, Roboto, Helvetica, sans-serif;
  line-height: 33px;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.place-project-card p {
  text-transform: capitalize;
}


.css-1jqq78o-placeholder {
  text-transform: capitalize !important;
}

/* <!-- ======================== pagination-buttons ======================== --> */

.pagination-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.buttons-pagination {
  padding: 20px;
  background: var(--wight-color);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.buttons-pagination.active {
  background: var(--main-color) !important;
  color: var(--wight-color) !important;
}

.button-toggle {
  padding: 20px;
  background: var(--wight-color);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.pagination-buttons li {
  cursor: pointer;
}

/* <!-- ======================== Gallery ======================== --> */

.css-rd9bp {
  background: #ffffff57 !important;
  backdrop-filter: blur(6px) !important;
}


/* <!-- ======================== achievement ======================== --> */


.explain-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}


.explain-text .main-button {
  margin: 50px 0;
}

.explain-text h2 {
  font-size: 37px;
  font-weight: 500;
}

.explain-text p {
  color: var(--gray-color);
  font-size: 17px;
  font-weight: 400;
}


.grid-achievement {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  position: relative;
  gap: 20px;
  width: 100%;
  gap: 40px;
}

.grid-achievement h3 {
  color: var(--main-color);
  font-size: 40px;
  font-weight: 700;
}

.grid-achievement p {
  color: var(--color-text);
  font-size: 18px;
  font-weight: 400;

}

/* <!-- ======================== AboutSection ======================== --> */
.about-section {
  margin-top: 70px;
}

.side-div-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.side-div-grid h2 {
  font-size: 30px;
}

.img-size {
  aspect-ratio: 2/1.13;
  border-radius: 25px;
  height: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.icon-div {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(--main-color);
  flex-shrink: 0;
}

.icon-div img {
  width: 70% !important;
  height: 70% !important;
}

.icon-div-text {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon-div-text p {
  line-height: 18px;
  margin-top: 5px;
}

.icon-about-side {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


/* <!-- ======================== destination  ======================== --> */

.card-destination {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  height: 280px;
  cursor: pointer;

}

.destination-section .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.card-destination .arrow-icon, .img-destination {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.card-destination:hover .arrow-icon {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

.card-destination:hover .img-destination {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.card-destination::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.52%, rgba(0, 0, 0, 0.70) 100%);
  left: 0;
  top: 0;
  z-index: 0;
}

.img-destination {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tittle-div-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 90%;
  z-index: 1;
}

.travel-count {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  background: var(--main-color);
  padding: 10px 15px;
  border-radius: 25px;
  z-index: 1;


}

.travel-count p, .tittle-div-arrow h2 {
  color: var(--wight-color) !important;
  font-weight: 300;
  text-transform: capitalize;
}

/* <!-- ======================== about-page  ======================== --> */

.about-page {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.content-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid-style-one .card {
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid-style-one .counter img {
  width: 50px;
  height: 50px;
}

/* ======================== why-us-sec ======================== */

.grid-why-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}

.card-why-us {
  border-radius: 40px;
  background: var(--card-color);
  padding: 30px;

  cursor: pointer;
}

.title-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

}

.title-arrow h2 {
  font-weight: 500;
}

.card-why-us p {
  color: var(--gray-color);
  transition: 0.3s;
  height: 0;
  opacity: 0;
  overflow-y: clip;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.card-why-us.active p {
  opacity: 1;
  margin: 5px 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 20px;
}

.title-arrow img {
  width: 24px;
  aspect-ratio: 1/1;
}

/* <!-- ======================== icon-whatsapp  ======================== --> */

.icon-contact {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 60px;
  z-index: 555555;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon-whatsapp {
  padding: 10px;
  height: 60px;
  background: #fafafa;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.icon-whatsapp img {
  width: 100%;
}

/* <!-- ======================== project-page  ======================== --> */


.project-page .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* <!-- ======================== project-page  ======================== --> */

.div-content h1, .div-content h2, .div-content h3, .div-content h4, .div-content h5, .div-content h6 {
  font-size: 25px;
  margin: 10px 0;
  color: var(--main-color) !important;
  font-weight: 500;
  text-transform: capitalize;
}

.div-content h1 span, .div-content h2 span, .div-content h3 span, .div-content h4 span, .div-content h5 span, .div-content h6 span .div-content strong {
  color: var(--main-color) !important;

}

.swiper-page-project {
  width: 100%;
}

.content-travle-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  width: 100%;
  margin-top: 10px;
}

.google-map-code iframe {
  border: 0px;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  margin-top: 20px;
}

.google-map-code {
  width: 100%;

}



.grid-project-div {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 10px;
}

@media (max-width: 1200px) {
  .grid-project-div {

    grid-template-columns: 1fr;

  }
}

.side-contact-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 0px;
  gap: 20px;
}



.div-3 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .div-3 {
    margin-top: 20px;
  }
}

.div-side {
  align-items: flex-end;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
}

.div-side .button {
  width: 100%;
}

.div-5 {
  color: var(--neutral-100, #fff);
  text-align: center;
  font-feature-settings: "clig"off, "liga"off;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background-color: var(--main-color, #e6612e);
  padding: 14px 20px;
  font: 700 16px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-5 {
    white-space: initial;
  }
}

.div-6 {
  color: #000;
  text-align: right;
  align-self: center;
  margin-top: 33px;
  white-space: nowrap;
  font: 400 18px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-6 {
    white-space: initial;
  }
}

.div-7 {
  align-self: stretch;
  display: flex;
  margin-top: 13px;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 991px) {
  .div-7 {
    margin-left: 1px;
    justify-content: center;
  }
}

.div-8 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-9 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-9 {
    white-space: initial;
  }
}

.div-10 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-10 {
    white-space: initial;
  }
}

.div-11 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-12 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-12 {
    white-space: initial;
  }
}

.div-13 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-13 {
    white-space: initial;
  }
}

.div-14 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-15 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-15 {
    white-space: initial;
  }
}

.div-16 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-16 {
    white-space: initial;
  }
}

.div-17 {
  align-self: stretch;
  display: flex;
  margin-top: 17px;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 991px) {
  .div-17 {
    margin-left: 1px;
    justify-content: center;
  }
}

.div-18 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-19 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-19 {
    white-space: initial;
  }
}

.div-20 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-20 {
    white-space: initial;
  }
}

.div-21 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-22 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-22 {
    white-space: initial;
  }
}

.div-23 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-23 {
    white-space: initial;
  }
}

.div-24 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-25 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-25 {
    white-space: initial;
  }
}

.div-26 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-26 {
    white-space: initial;
  }
}

.div-27 {
  align-self: stretch;
  display: flex;
  margin-top: 17px;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 991px) {
  .div-27 {
    margin-left: 1px;
    justify-content: center;
  }
}

.div-28 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-29 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-29 {
    white-space: initial;
  }
}

.div-30 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-30 {
    white-space: initial;
  }
}

.div-31 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-32 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-32 {
    white-space: initial;
  }
}

.div-33 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-33 {
    white-space: initial;
  }
}

.div-34 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-35 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  font: 300 14px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-35 {
    white-space: initial;
  }
}

.div-36 {
  color: var(--text-color, #001433);
  text-align: right;
  white-space: nowrap;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-36 {
    white-space: initial;
  }
}

.div-37 {
  align-items: center;
  border-radius: 20px;
  background-color: var(--card-color, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  padding: 20px;
}

.text-p-side {
  color: var(--text-color, #001433);
  letter-spacing: 0.4px;
  white-space: nowrap;
  font: 400 20px/20px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}



.img {
  aspect-ratio: 1.1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  overflow: hidden;
  margin-top: 16px;
}

.main-contact-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
}



.div-39 {
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

}

@media (max-width: 991px) {
  .div-39 {
    max-width: 100%;
    margin-top: 20px;
  }
}

.div-40 {
  justify-content: flex-end;
  align-self: stretch;
  margin-right: -20px;
}

@media (max-width: 991px) {
  .div-40 {
    max-width: 100%;
  }
}

.div-41 {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 360px;
}


.column-3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 0px;
}

@media (max-width: 991px) {
  .column-3 {
    width: 100%;
  }
}

.div-details-text-name {
  align-items: start;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  gap: 20px;
}



.div-43 {
  color: var(--main-color, #e6612e);
  text-align: left;
  font: 400 20px/27px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.h1-page-title {
  align-self: stretch;
  color: var(--text-color, #001433);
  text-align: left;
  margin-top: 17px;
  font: 400 40px/63px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
  line-height: 43px;
}


.div-category-details {
  display: flex;
  margin-top: 17px;
  width: 308px;
  max-width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}



.div-category {
  color: var(--gray-color, #455267);

  white-space: nowrap;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--card-color, #fff);

  padding: 5px 20px;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica, sans-serif;
  width: fit-content;
  font-size: 14px;
}


.div-47 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--card-color, #fff);
  flex-grow: 1;
  padding: 5px 20px;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-47 {
    white-space: initial;
  }
}

.div-48 {
  color: var(--gray-color, #455267);
  text-align: right;
  white-space: nowrap;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--card-color, #fff);
  aspect-ratio: 2.121212121212121;
  padding: 5px 20px;
  font: 400 16px/23px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-48 {
    white-space: initial;
  }
}

.div-49 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .div-49 {
    max-width: 100%;
    padding-left: 20px;
  }
}

.div-50 {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 11px;
}

@media (max-width: 991px) {
  .div-50 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.div-51 {
  color: var(--gray-color, #455267);
  text-align: left;
  letter-spacing: 0.36px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 18px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}

.div-52 {
  justify-content: space-between;
  align-self: start;
  display: flex;
  margin-top: 52px;
  width: 367px;
  max-width: 100%;
  gap: 20px;
}

@media (max-width: 991px) {
  .div-52 {
    margin-top: 40px;
  }
}

.div-53 {
  justify-content: space-between;
  display: flex;
  gap: 10px;
}

.div-54 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-55 {
  color: var(--text-color, #001433);
  letter-spacing: 0.36px;
  white-space: nowrap;
  font: 400 18px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-55 {
    white-space: initial;
  }
}

.div-56 {
  color: var(--gray-color, #455267);
  letter-spacing: 0.32px;
  align-self: end;
  margin-top: 10px;
  white-space: nowrap;
  font: 400 16px/16px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-56 {
    white-space: initial;
  }
}

.div-57 {
  justify-content: center;
  align-items: center;
  border-radius: 135px;
  background-color: var(--main-color, #e6612e);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  margin: auto 0;
  padding: 8px;
}

.img-3 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
}

.div-58 {
  justify-content: space-between;
  display: flex;
  gap: 10px;
}

.div-59 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-60 {
  color: var(--text-color, #001433);
  letter-spacing: 0.36px;
  white-space: nowrap;
  font: 400 18px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-60 {
    white-space: initial;
  }
}

.div-61 {
  color: var(--gray-color, #455267);
  letter-spacing: 0.32px;
  align-self: end;
  margin-top: 10px;
  white-space: nowrap;
  font: 400 16px/16px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-61 {
    white-space: initial;
  }
}

.div-62 {
  justify-content: center;
  align-items: center;
  border-radius: 135px;
  background-color: var(--main-color, #e6612e);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  margin: auto 0;
  padding: 10px 9px;
}

.img-4 {
  aspect-ratio: 1.1;
  object-fit: contain;
  object-position: center;
  width: 22px;
  fill: var(--wight-color, #fff);
  overflow: hidden;
}

.div-63 {
  justify-content: space-between;
  align-self: start;
  display: flex;
  margin-top: 40px;
  width: 367px;
  max-width: 100%;
  padding-left: 2px;
  gap: 20px;
}

.div-icon-text {
  justify-content: space-between;
  display: flex;
  gap: 10px;
}

.text-icon-project-detail {
  display: flex;
  flex-direction: column;
}

.text-details-icon-b {
  color: var(--text-color, #001433);
  letter-spacing: 0.36px;
  white-space: nowrap;
  font: 400 18px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .text-details-icon-b {
    white-space: initial;
  }
}

.div-67 {
  color: var(--gray-color, #455267);
  letter-spacing: 0.32px;
  align-self: end;
  margin-top: 10px;
  white-space: nowrap;
  font: 400 16px/16px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-67 {
    white-space: initial;
  }
}

.div-icon-project-detail {
  justify-content: center;
  align-items: center;
  border-radius: 135px;
  background-color: var(--main-color, #e6612e);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  margin: auto 0;
  padding: 8px;
}

.img-5 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
}

.div-69 {
  justify-content: space-between;
  display: flex;
  gap: 10px;
}

.div-70 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.div-71 {
  color: var(--text-color, #001433);
  letter-spacing: 0.36px;
  white-space: nowrap;
  font: 400 18px/18px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-71 {
    white-space: initial;
  }
}

.div-72 {
  color: var(--gray-color, #455267);
  letter-spacing: 0.32px;
  align-self: end;
  margin-top: 10px;
  white-space: nowrap;
  font: 400 16px/16px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-72 {
    white-space: initial;
  }
}

.div-73 {
  justify-content: center;
  align-items: center;
  border-radius: 135px;
  background-color: var(--main-color, #e6612e);
  align-self: center;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  margin: auto 0;
  padding: 8px;
}






.gird-img-project-details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 10px;
}


.img-7 {
  aspect-ratio: 1.98;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .img-7 {
    max-width: 100%;
  }

  .div-41 {
    grid-template-columns: 1fr;
  }
}

.slide-image-details {
  width: 100%;
  display: grid;

}

.slide-image-details img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.div-img-project-details {
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .slide-image-details {
    max-width: 100%;
  }
}

.div-76 {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .div-76 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}


.img-8 {
  aspect-ratio: 1.19;
  object-fit: contain;
  object-position: center;
  width: 206px;
  align-self: stretch;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .img-8 {
    margin-top: 8px;
  }
}


.img-9 {
  aspect-ratio: 1.18;
  object-fit: contain;
  object-position: center;
  width: 205px;
  align-self: stretch;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .img-9 {
    margin-top: 8px;
  }
}

.div-77 {
  align-self: stretch;
  overflow: hidden;
  position: relative;
  display: flex;
}

@media (max-width: 991px) {
  .div-77 {
    margin-top: 8px;
  }
}

.img-10 {

  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.img-11 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 60px;
  overflow: hidden;
  max-width: 100%;
  margin: 7px 0 6px;
}

.div-78 {
  color: #000;
  letter-spacing: 0.6px;

  white-space: nowrap;
  font: 400 30px/30px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-78 {
    margin-top: 40px;
    white-space: initial;
  }
}

.description-p-travel {
  align-self: stretch;
  color: var(--gray-color, #455267);
  text-align: start;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica, sans-serif;
  line-height: 31px;
}



.div-80 {
  color: #000;
  letter-spacing: 0.6px;
  align-self: end;
  white-space: nowrap;
  margin: 50px -20px 0 0;
  font: 400 30px/30px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .div-80 {
    margin-top: 40px;
    white-space: initial;
  }
}

.div-81 {
  align-self: end;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin: 30px -20px 0 0;
}

@media (max-width: 991px) {
  .div-81 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.div-82 {
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
}

.div-83 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-12 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-84 {
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
}

.div-85 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-13 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-86 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-87 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-14 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-88 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-89 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-15 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-90 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-91 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-16 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-92 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-93 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-17 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-94 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-95 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-18 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-96 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-97 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-19 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-98 {
  align-self: end;
  display: flex;
  width: 100%;
  padding-left: 61px;
  flex-direction: column;
  margin: 20px -20px 0 0;
}

@media (max-width: 991px) {
  .div-98 {
    max-width: 100%;
    padding-left: 20px;
  }
}

.div-99 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 991px) {
  .div-99 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.div-100 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-101 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-20 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-102 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-103 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-21 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-104 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-105 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-22 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-106 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-107 {
  color: var(--gray-color, #455267);
  text-align: right;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-23 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-108 {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.div-109 {
  color: var(--gray-color, #455267);
  text-align: right;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-24 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-110 {
  justify-content: flex-end;
  align-self: end;
  display: flex;
  margin-top: 20px;
  width: 276px;
  max-width: 100%;
  gap: 5px;
}

.div-111 {
  color: var(--gray-color, #455267);
  text-align: right;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 20px/52px Alexandria, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.img-25 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}


/* ========================== Admin ======================================================= */



.grid-dashboard {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 20px;
}


.slide-img-upload {
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 1 / 1;
}

.slide-img-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-image-details .swiper {
  width: 100% !important;
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.img-upload-div {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  position: relative;
  gap: 20px;
}

/* nav-par-side ======================================================= */

.nav-par-side {

  background: var(--card-color);
  padding-left: 10px;
  height: 100vh;
  position: sticky;
  top: 0;
}

.nav-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-sec a {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 45px 0 0 45px;
  gap: 20px;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -ms-transition: 0.1s;
  -o-transition: 0.1s;
  text-transform: capitalize;
  justify-content: space-between;
}

.nav-sec a:hover {
  background: #262e3f;
  color: white !important;
}

.nav-sec a.active {
  background: var(--main-color);
}

.nav-sec a {
  color: var(--wight-color);
}

.a-logo-dashboard {
  margin-bottom: 20px !important;
  display: block;
  padding: 20px;
  text-align: center;
}

.list-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-sec span {
  display: block;
  width: 40px;
  height: 40px;
  background: rgb(45 51 63);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}


/* input ======================================================= */


.drag-file-area {
  border: 2px dashed var(--main-color);
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
}

.drag-file-area .upload-icon {
  font-size: 50px;
}

.drag-file-area h3 {
  font-size: 26px;
  margin: 15px 0;
}

.drag-file-area label {
  font-size: 19px;
}

.drag-file-area label .browse-files-text {
  color: var(--main-color);
  font-weight: bolder;
  cursor: pointer;
  margin-left: 6px;
}

.browse-files span {
  position: relative;
  top: -25px;
}

.default-file-input {
  opacity: 0;
}

/* container-dashboard ======================================================= */
select {
  padding: 15px 10px;
  border-radius: 5px;
}

.add-new-travel form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-dashboard {
  width: 90%;
  margin: auto;
  padding: 30px 0;
}

.container-dashboard input {

  padding: 15px 10px;
  background-color: var(--card-color);
  border-radius: 5px;
}

.container-dashboard .add-new-tittle {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.add-new-tittle .icon-select {
  flex-direction: column;
}

.add-new-tittle .swiper {
  padding: 10px;
  background: var(--card-color);
  border-radius: 15px;
}

.container-dashboard .add-new-tittle h2 {
  font-size: 20px;
  font-weight: 500;

}

.add-new-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.multer-add-new {

  flex-direction: row !important;
}

.ql-container {
  min-height: 400px;

}

.quill {
  direction: ltr;
}

/* Upload */
.uploadPicturesWrapper div {
  justify-content: flex-start !important;
}

.uploadPictureContainer {
  width: 200px !important;
  height: auto !important;
  margin: 2% 5% !important;
  padding: 10px !important;
}

.uploadPictureContainer img {
  height: 200px !important;
}

/* update-delete-buttons */

.container-dashboard .grid-style {
  direction: ltr;
}

.card-div-message .update-delete-buttons {
  margin-top: 0;
}

.update-delete-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.update, .delete {
  border-radius: 10px;
  padding: 15px 20px;
  color: var(--wight-color) !important;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.update {
  background: var(--main-color) !important;

}

.delete {
  background: #ffa3a38c !important;
  color: var(--color-text) !important;
}

/* <!-- ======================== card-contact ======================== --> */


.services-grid .card {
  background-color: var(--card-color);
  justify-content: space-between;
}

.card .img-card {
  width: 60px;
  aspect-ratio: 1/1;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg) brightness(107%) contrast(105%);
}

.our-statistics .card {
  text-align: center;
  align-items: center;
  padding: 100px 20px;
}

.our-statistics .card h1 {
  color: var(--main-color);
  font-size: 30px;
  font-weight: 600;

}




/* <!-- ======================== react-tel-input ======================== --> */


.react-tel-input .form-control {
  width: 100% !important;
  background: var(--background-color) !important;
  border: 0 !important;
}



.react-tel-input .country-list .country-name {
  color: var(--Dark-color);
}

.react-tel-input .form-control:focus {
  border: 0 !important;
  box-shadow: inherit !important;
}

.react-tel-input .country-list::-webkit-scrollbar {
  display: none;
}

/* <!-- ======================== form ======================== --> */


.form-div-parent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 20px;
}

@media (max-width: 1100px) {
  .form-div-parent {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.name-phone-div {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-container {
  width: 100%;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 55;
}

.form-container form input {
  padding: 10px;
  color: var(--color-text);
  font-size: 20px;
  background: transparent;
}

.form-container button {
  padding: 20px 0;
  background-color: var(--main-color);
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 18px;
}

.form-container form input::placeholder,
textarea::placeholder {
  color: var(--blog-color-p);
  font-size: 16px;
  text-transform: capitalize;
}

section {
  position: relative;
  z-index: 5;
  overflow-x: clip;
}

.section-contact-page {
  margin-top: 130px;
}

textarea {
  width: 100%;
  height: 361px;
  max-width: 100%;
  max-height: 410px;
  font-size: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  width: 100%;
  resize: none;
  background: var(--card-color);

  color: var(--color-text);
  background: var(--background-color);
}

.div-img-input {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  gap: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;

  background: var(--background-color);
  /* border: 1px solid #2a185b; */
}

.div-img-input img {
  width: 25px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg) brightness(107%) contrast(105%);
}

.textarea-div {
  align-items: start !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

input {
  width: 100%;
}

@media (max-width: 700px) {
  .name-phone-div {
    flex-direction: column;
  }
}

.contact-about {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.details-about-contact {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-about-contact p {
  color: var(--color-text);
}

.details-about-contact img {
  width: 26px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg) brightness(107%) contrast(105%);
  -webkit-filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg) brightness(107%) contrast(105%);
}

.details-about-contact b {
  text-transform: capitalize;
}


.section-contact-form .div-img-input {
  background-color: var(--wight-color) !important;
}

.section-contact-form input, .section-contact-form .form-control, .section-contact-form textarea {
  background: transparent !important;
}



/* <!-- ======================== review-grid ======================== --> */

.review-grid {
  position: relative;
  z-index: 7;
  /* direction: rtl !important; */
}

.card-review {
  width: 300px;
  text-align: center;
  text-transform: capitalize;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background: var(--card-color);
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}

.swiper-slide {
  min-height: 170px !important;
  height: initial !important;
}

.img-reviewer {
  width: 60px;
  height: 60px;
  background: var(--card-color);
  border-radius: 15px;
  overflow: hidden;
}

.img-reviewer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-name-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewer-text {
  color: var(--blog-color-p);
  margin-top: 10px;
  text-align: initial;
}

.about-reviewer {
  text-align: start;
}

.about-reviewer p {
  color: var(--blog-color-p);
}

.about-reviewer h2 {
  font-weight: 400;
}

.star-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin: 10px 0;
  justify-content: center;
  position: absolute;
  left: 20px;
  bottom: 8px;
}



/* <!-- ========================  footer ======================== --> */



footer {
  padding: 50px 0 10px 0;
  position: relative;
  margin-top: 50px;
  background: #202633;
}

.footer-section a {
  color: var(--main-color) !important;
}

.footer-cta {
  border-bottom: 1px solid #373636
}

.list-footer {

  display: flex;
  flex-direction: column;
  gap: 10px;

}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px
}

.cta-text {
  padding-left: 15px;
  display: inline-block
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px
}

.cta-text span {
  color: #757575;
  font-size: 15px
}

.footer-content {
  position: relative;
  z-index: 2
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%
}

.footer-logo {
  margin-bottom: 30px
}

.footer-logo img {
  max-width: 200px
}

.footer-widget {

  font-weight: 300;

}

.footer-widget h1 {
  color: var(--main-color);
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 30px;
}



.footer-text p {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--wight-color) !important;
  line-height: 28px
}

.footer-social-icon {
  color: var(--main-color);
}

footer .social-media {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  position: relative
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--main-color)
}

.footer-widget .website-ul {
  display: flex;
  flex-direction: column;
  gap: 5px
}

.footer-widget ul li a:hover {
  color: var(--main-color)
}

.footer-widget ul li a {
  color: var(--gray-color);
  text-transform: capitalize
}

.subscribe-form {
  position: relative;
  overflow: hidden
}

.subscribe-form form {
  display: flex
}

.subscribe-form input {
  width: 100%;
  padding: 10px;
  background: #2a2544;
  border: none;
  color: #fff;
  outline: none
}

.subscribe-form button {
  background: var(--main-color);
  padding: 13px 20px;
  border: 1px solid var(--main-color);
  cursor: pointer
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg)
}

.copyright-area {
  background: var(--Dark-color);
  padding: 25px 0
}

.copyright-area .footer-copy-write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.made-with-love {

  margin-top: 20px;
}

.copyright-text p {
  margin: 0;
  font-size: 16px;
  color: var(--wight-color) !important;
}

.copyright-text p a {
  color: var(--wight-color)
}

.footer-menu ul {
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px
}

.footer-menu li:hover a {
  color: var(--main-color)
}

.footer-menu li a {
  font-size: 16px;
  color: var(--gray-color)
}

.footer-content .row-container {
  grid-template-columns: 1fr 200px 200px;
  display: grid;
  gap: 40px;
}



@media(max-width: 1300px) {
  .footer-content .row-container {
    grid-template-columns: 100% !important
  }

  .bg-code {
    width: 200%;

  }

  .text-about-site {
    width: 95%;
  }

  .img-ul-li-2 {
    width: 150px;
    height: 150px;
    left: 190px;
    bottom: -80px;

  }

  .img-ul-li-3 {
    width: 120px;
    height: 120px;
    left: -10px;
    top: -22px;

  }

  .about-site-parent .img-ul-li-1 {
    display: none;
  }

  .about-site {
    height: 280px;
  }

  .about-site h1 {
    font-size: 50px;
  }

  .about-site p {
    font-size: 20px;
    line-height: 40px;
  }
}